.app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Roboto;
    background-color: #f8f9fd;
    --blue: #3777ff;
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    }

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: var(--blue);
    border: 1px solid var(--blue);
    color: #fff;
    padding: 10px 20px;
}


.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

h1 {
    color: white;
    background-color: var(--blue);
    margin: 0;
    padding: 24px;
    text-align: left;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
}

.dash_header {
    font-size: 28px;
    line-height: 38px;
}

.logout {
    cursor: pointer;
    line-height: 38px;
}

.logout:after {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    content: '';
    background-image: url('/logout.svg');
    background-size: contain;
}


.table-container {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

th,
td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: lightgreen;
    font-weight: bold;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

.players {
    margin: 20px;
    max-width: 1200px;
}

/*Mobile version*/
@media (max-width: 767px) {
    th,
    td {
        font-size: 14px;
    }
}