body {
	--blue: #3777ff;
	margin: 0;
	color: var(--tg-theme-text-color);
	background: var(--tg-theme-bg-color);
	font-family: 'Roboto';
}

h1 {
	text-align: center;
}

button {
	cursor: pointer;
}

.wrapper {
	height: 100dvh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header {
	width: 100%;
    padding: 12px 24px 0px 24px;
    font-size: 20px;
    text-align: left;
    font-weight: bold;
    box-sizing: border-box;
}

.table {
	overflow: auto;
	margin: 12px;
	width: 100%;
	height: 100%;
}

.grid div {
	position: relative;
	margin: 0 auto;
}

.checkbox {
	width: 32px;
	height: 32px;
	border-radius: 0px;
	-webkit-border-radius: 0px;
}

.checkbox_border.active {
    width: 36px;
    height: 36px;
    border: 4px solid orange;
    position: absolute !important;
    top: 1px;
    left: 2px;
    box-sizing: border-box;
    border-radius: 2px;
    pointer-events: none;
}

.checkbox:checked + .checkbox_border.active {
	border-color: limegreen;
}

.jump_wrapper {
    margin: 0 0 12px 0;
    display: flex;
}

.jump_number  {
	font-family: 'Roboto';
	margin: 0px;
    padding: 8px;
    height: 50px;
    font-size: 18px;
    border: 2px solid var(--blue);
    border-radius: 0px;
    box-sizing: border-box;
    flex-grow: 1;
}

.jump_button {
	font-family: 'Roboto';
	margin: 0px;
    padding: 8px;
    height: 50px;
    font-size: 18px;
    background-color: var(--blue);
    border: none;
    color: white;
    cursor: pointer;
    width: 140px;
}

.stats_wrapper {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.stats_right {
	text-align: right;
}

a {
	color: var(--blue);
}

.coin:after {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	content: '';
	background-image: url('/coin.svg');
}

.coin span {
	vertical-align: middle;
}

.mtop {
	margin-top: 4px;
}

/*Safari only styles*/
_::-webkit-full-page-media, _:future, :root .safari_only {
  left: 0;
  border-radius: 8px;
}

/*Mobile version*/
@media (max-width: 767px) {
	.header {
		font-size: 14px;
	}
}